import React, { FC } from 'react';
import styles from './MessagesList.module.css';

interface MessagesListProps {}

const MessagesList: FC<MessagesListProps> = () => (
  <div className={styles.MessagesList}>
    MessagesList Component
  </div>
);

export default MessagesList;
