import React, { FC } from 'react';
import styles from './Manage.module.css';

interface ManageProps {}

const Manage: FC<ManageProps> = () => (
  <div className={styles.Manage}>
    Manage Component
  </div>
);

export default Manage;
