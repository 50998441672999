import React, { FC } from 'react';
import styles from './WidgetSm.module.css';
import VisibilityIcon from '@mui/icons-material/Visibility';

interface WidgetSmProps {}

const WidgetSm: FC<WidgetSmProps> = () => (
  <div className={styles.WidgetSm}>
    <span className={styles.WidgetSmTitle}>New Join Members</span>
    <ul className={styles.WidgetSmList}>
      <li className={styles.WidgetSmListItem}>
        <img src="https://amerlin.keantex.com/wp-content/uploads/2019/01/profilo.jpg?57d607&57d607" alt="" className={styles.WidgetSmImg} />
        <div className={styles.WidgetSmUser}>
          <span className={styles.WidgetSmUsername}>Andrea Merlin</span>
          <span className={styles.WidgetSmUserTitle}>Senior Developer</span>
        </div>
        <button className={styles.WidgetSmButton}>
        <VisibilityIcon className={styles.WidgetSmIcon}/>
          Display
        </button>
      </li>

      <li className={styles.WidgetSmListItem}>
        <img src="https://amerlin.keantex.com/wp-content/uploads/2019/01/profilo.jpg?57d607&57d607" alt="" className={styles.WidgetSmImg} />
        <div className={styles.WidgetSmUser}>
          <span className={styles.WidgetSmUsername}>Andrea Merlin</span>
          <span className={styles.WidgetSmUserTitle}>Senior Developer</span>
        </div>
        <button className={styles.WidgetSmButton}>
        <VisibilityIcon className={styles.WidgetSmIcon}/>
          Display
        </button>
      </li>

      <li className={styles.WidgetSmListItem}>
        <img src="https://amerlin.keantex.com/wp-content/uploads/2019/01/profilo.jpg?57d607&57d607" alt="" className={styles.WidgetSmImg} />
        <div className={styles.WidgetSmUser}>
          <span className={styles.WidgetSmUsername}>Andrea Merlin</span>
          <span className={styles.WidgetSmUserTitle}>Senior Developer</span>
        </div>
        <button className={styles.WidgetSmButton}>
        <VisibilityIcon className={styles.WidgetSmIcon}/>
        Display
        </button>
      </li>


    </ul>
  </div>
);

export default WidgetSm;
