import './App.css';
import Home from './pages/Home/Home';
import SideBar from './components/SideBar/SideBar';
import TopBar from './components/TopBar/TopBar';
import { Route, Routes } from 'react-router-dom';
import UserList from './pages/UserList/UserList';
import User from './pages/User/User';
import NewUser from './pages/NewUser/NewUser';
import ProductList from './pages/ProductList/ProductList';
import AnalyticsList from './pages/AnalyticsList/AnalyticsList';
import SalesList from './pages/SalesList/SalesList';
import TransactionsList from './pages/TransactionsList/TransactionsList';
import ReportsList from './pages/ReportsList/ReportsList';
import MessagesList from './pages/MessagesList/MessagesList';
import FeedBacksList from './pages/FeedBacksList/FeedBacksList';
import MailsList from './pages/MailsList/MailsList';
import AdminReports from './pages/AdminReports/AdminReports';
import AdminAnalytics from './pages/AdminAnalytics/AdminAnalytics';
import Manage from './pages/Manage/Manage';
import { useAuth0 as auth0 } from "@auth0/auth0-react";
import HomePublic from './pages/HomePublic/HomePublic';

function App() {
  const { isAuthenticated } = auth0();
  return (
    <div className="App">
      {!isAuthenticated &&
        <>
          <HomePublic/>
        </>
      }
      {isAuthenticated &&
        <>
          <TopBar />
          <div className='container'>
            <SideBar />
            <Routes>
              <Route path='/' element={<Home />} />
              <Route path='/users' element={<UserList />} />
              <Route path='/user/:userId' element={<User />} />
              <Route path='/newuser/' element={<NewUser />} />
              <Route path='/products/' element={<ProductList />} />
              <Route path='/analytics/' element={<AnalyticsList />} />
              <Route path='/sales' element={<SalesList />} />
              <Route path='/transactions' element={<TransactionsList />} />
              <Route path='/reports' element={<ReportsList />} />
              <Route path='/mails' element={<MailsList />} />
              <Route path='/feedbacks' element={<FeedBacksList />} />
              <Route path='/messages' element={<MessagesList />} />
              <Route path='/manage' element={<Manage />} />
              <Route path='/adminanalytcs' element={<AdminAnalytics />} />
              <Route path='/adminreports' element={<AdminReports />} />
            </Routes>
          </div>
        </>
      }
    </div>
  );
}

export default App;
