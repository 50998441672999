import React, { FC } from 'react';
import styles from './ReportsList.module.css';

interface ReportsListProps {}

const ReportsList: FC<ReportsListProps> = () => (
  <div className={styles.ReportsList}>
    ReportsList Component
  </div>
);

export default ReportsList;
