import React, { FC } from 'react';
import styles from './WidgetLg.module.css';

interface WidgetLgProps {}

const WidgetLg: FC<WidgetLgProps> = () => {
  return (
  <div className={styles.WidgetLg}>
    <span className={styles.WidgetLgTitle}>Latest transactions</span>
    <table className={styles.WidgetLgTable}>
      <thead>
      <tr className={styles.WidgetLgTr}>
        <th className={styles.WidgetLgTh}>Customer</th>
        <th className={styles.WidgetLgTh}>Date</th>
        <th className={styles.WidgetLgTh}>Amount</th>
        <th className={styles.WidgetLgTh}>Status</th>
      </tr>
      </thead>
      <tbody>
      <tr className={styles.WidgetLgTr}>
        <td className={styles.WidgetLgUser}>
          <img src="https://amerlin.keantex.com/wp-content/uploads/2019/01/profilo.jpg?57d607&57d607" alt="profile" className={styles.WidgetLgImg}></img>
          <span className={styles.WidgetLgName}>Andrea Merlin</span>
        </td>
        <td className={styles.WidgetLgDate}>2 Jub 2023</td>
        <td className={styles.WidgetLgAmount}>$122.00</td>
        <td className={styles.WidgetLgStatus}><button className={styles.WidgegLgButton}>Approved</button></td>
      </tr>
      <tr className={styles.WidgetLgTr}>
        <td className={styles.WidgetLgUser}>
          <img src="https://amerlin.keantex.com/wp-content/uploads/2019/01/profilo.jpg?57d607&57d607" alt="profile" className={styles.WidgetLgImg}></img>
          <span className={styles.WidgetLgName}>Andrea Merlin</span>
        </td>
        <td className={styles.WidgetLgDate}>2 Jub 2023</td>
        <td className={styles.WidgetLgAmount}>$122.00</td>
        <td className={styles.WidgetLgStatus}><button className={styles.WidgegLgButton}>Approved</button></td>
      </tr>
      <tr className={styles.WidgetLgTr}>
        <td className={styles.WidgetLgUser}>
          <img src="https://amerlin.keantex.com/wp-content/uploads/2019/01/profilo.jpg?57d607&57d607" alt="profile" className={styles.WidgetLgImg}></img>
          <span className={styles.WidgetLgName}>Andrea Merlin</span>
        </td>
        <td className={styles.WidgetLgDate}>2 Jub 2023</td>
        <td className={styles.WidgetLgAmount}>$122.00</td>
        <td className={styles.WidgetLgStatus}><button className={styles.WidgegLgButton}>Approved</button></td>
      </tr>
      </tbody>
    </table>
  </div>
  );
};

export default WidgetLg;
