import React, { FC } from 'react';
import styles from './MailsList.module.css';

interface MailsListProps {}

const MailsList: FC<MailsListProps> = () => (
  <div className={styles.MailsList}>
    MailsList Component
  </div>
);

export default MailsList;
