import React, { FC } from 'react';
import styles from './AnalyticsList.module.css';

interface AnalyticsListProps {}

const AnalyticsList: FC<AnalyticsListProps> = () => (
  <div className={styles.AnalyticsList}>
    AnalyticsList Component
  </div>
);

export default AnalyticsList;
