import React, { FC } from 'react';
import styles from './TransactionsList.module.css';

interface TransactionsListProps {}

const TransactionsList: FC<TransactionsListProps> = () => (
  <div className={styles.TransactionsList}>
    TransactionsList Component
  </div>
);

export default TransactionsList;
