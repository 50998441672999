import { ChartDataGridData } from "../Models/ChartDataGridData";

 export const data: ChartDataGridData[] = [
    {
      name: 'Jan',
      "Active User": 4000,
    },
    {
      name: 'Feb',
      "Active User": 3000,
    },
    {
      name: 'Mar',
      "Active User": 2000,
    },
    {
      name: 'Apr',
      "Active User": 2780,
    },
    {
      name: 'May',
      "Active User": 1890,
    },
    {
      name: 'Jun',
      "Active User": 2390,
    },
    {
      name: 'Jul',
      "Active User": 3490,
    },
    {
      name: 'Agu',
      "Active User": 1000,
    },
    {
      name: 'Sep',
      "Active User": 4500,
    },
    {
      name: 'Oct',
      "Active User": 4490,
    },
    {
      name: 'Nov',
      "Active User": 1490,
    },
    {
      name: 'Dec',
      "Active User": 490,
    },
  ];