import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import { Destination } from "../../Models/Destination";

export interface DestinationState {
    Destinations: Destination[];
}

const initialState: DestinationState = {
    Destinations: [],
};

export const counterSlice = createSlice({
  name: "destinations",
  initialState,
  reducers: {
    setPlacesData: (state, action: PayloadAction<Destination[]>) => {
        action.payload.forEach(element => {
            let newElement: Destination = {
              Id : element.Id,
              Description:  element.Description,
              Address: element.Address,
              City: element.City,
            };
            state.Destinations.push(newElement);
        });
    },
  },
});

// Action creators are generated for each case reducer function
export const { setPlacesData } = counterSlice.actions;
export default counterSlice.reducer;
