import { CalendarToday, LocationSearching, MailOutline, PermIdentitySharp, PhoneAndroid, Publish} from '@mui/icons-material';
import React, { FC } from 'react';
import { Link } from 'react-router-dom';
import styles from './User.module.css';

interface UserProps {}

const User: FC<UserProps> = () => (
  <div className={styles.User}>
    <div className={styles.UserTitleContainer}>
      <h1 className={styles.UserTitle}>Edit User</h1>
      <Link to="/newUser/">
        <button className={styles.UserAddButton}>Create</button>
      </Link>
    </div>
    <div className={styles.UserContainer}>
      <div className={styles.UserShow}>
        <div className={styles.UserShowTop}>
          <img src="https://amerlin.keantex.com/wp-content/uploads/2019/01/profilo.jpg?57d607&57d607" alt="profile" className={styles.UserShowImg} />
          <div className={styles.UserShowTopTitle}>
            <span className={styles.UserShowUsername}>Andrea Merlin</span>
            <span className={styles.UserShowUserTitle}>Software Engineer</span>
          </div>
        </div>
        <div className={styles.UserShowBottom}>
          <span className={styles.UserShowTitle}>Account Details</span>
            <div className={styles.UserShowInfo}>
              <PermIdentitySharp className={styles.UserShowIcon} />
              <span className={styles.UserShowInfoTitle}>amerlin</span>
            </div>
            <div className={styles.UserShowInfo}>
              <CalendarToday className={styles.UserShowIcon} />
              <span className={styles.UserShowInfoTitle}>02.11.1977</span>
            </div>
            <span className={styles.UserShowTitle}>Contact Details</span>
            <div className={styles.UserShowInfo}>
              <PhoneAndroid className={styles.UserShowIcon} />
              <span className={styles.UserShowInfoTitle}>+1 123 456 678</span>
            </div>
            <div className={styles.UserShowInfo}>
              <MailOutline className={styles.UserShowIcon} />
              <span className={styles.UserShowInfoTitle}>amerlin@test.com</span>
            </div>
            <div className={styles.UserShowInfo}>
              <LocationSearching className={styles.UserShowIcon} />
              <span className={styles.UserShowInfoTitle}>Tortona | Italy</span>
            </div>
        </div>
      </div>
      <div className={styles.UserUpdate}>
        <span className={styles.UserUpdateTitle}></span>
        <form className={styles.UserUpdateForm}>
          <div className={styles.UserUpdateLeft}>
            <div className={styles.UserUpdateItem}>
              <label>UserName</label>
              <input type="text" placeholder="amerlin" className={styles.userUpdateInput}></input>
            </div>
            <div className={styles.UserUpdateItem}>
              <label>FullName</label>
              <input type="text" placeholder="Andrea Merlin" className={styles.userUpdateInput}></input>
            </div>
            <div className={styles.UserUpdateItem}>
              <label>Phone</label>
              <input type="text" placeholder="+1 123 456 678" className={styles.userUpdateInput}></input>
            </div>
            <div className={styles.UserUpdateItem}>
              <label>Email</label>
              <input type="text" placeholder="amerlin@test.com" className={styles.userUpdateInput}></input>
            </div>
            <div className={styles.UserUpdateItem}>
              <label>Address</label>
              <input type="text" placeholder="Tortona | Italy" className={styles.userUpdateInput}></input>
            </div>
          </div>
          <div className={styles.UserUpdateRight}>
            <div className={styles.UserUpdateUpload}>
              <img src="https://amerlin.keantex.com/wp-content/uploads/2019/01/profilo.jpg?57d607&57d607" alt="avatar" className={styles.UserUpdateImg}/>
              <label htmlFor="file"><Publish className={styles.UserUpdateIcon}/></label>
              <input type="file" id="file" style={{ display: "none" }} />
            </div>
            <button className={styles.UserUpdateButton}>Update</button>
          </div>
        </form>
      </div>
    </div>
  </div>
);

export default User;
