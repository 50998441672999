import React, { FC } from 'react';
import styles from './FeaturedInfo.module.css';
import ArrowDownward from '@mui/icons-material/ArrowDownward';
import ArrowUpward from '@mui/icons-material/ArrowUpward';

interface FeaturedInfoProps {}

const FeaturedInfo: FC<FeaturedInfoProps> = () => (
  <div className={styles.FeaturedInfo}>

    <div className={styles.FeaturedItem}>
      <span className={styles.FeaturedTitle}>
        Revanue
      </span>
      <div className={styles.FeaturedMoneyContainer}>
        <span className={styles.FeaturedMoney}>$2,415</span>
        <span className={styles.FeaturedMoneyRate}>-11.4<ArrowDownward className={styles.FeaturedIcon}/></span>
      </div>
      <span className={styles.FeaturedSub}>Compared to last mouth</span>
    </div>

    <div className={styles.FeaturedItem}>
      <span className={styles.FeaturedTitle}>
        Revanue
      </span>
      <div className={styles.FeaturedMoneyContainer}>
        <span className={styles.FeaturedMoney}>$2,415</span>
        <span className={styles.FeaturedMoneyRate}>-11.4<ArrowUpward className={styles.FeaturedIcon}/></span>
      </div>
      <span className={styles.FeaturedSub}>Compared to last mouth</span>
    </div>

    <div className={styles.FeaturedItem}>
      <span className={styles.FeaturedTitle}>
        Revanue
      </span>
      <div className={styles.FeaturedMoneyContainer}>
        <span className={styles.FeaturedMoney}>$2,415</span>
        <span className={styles.FeaturedMoneyRate}>-11.4<ArrowDownward className={styles.FeaturedIconNegative} /></span>
      </div>
      <span className={styles.FeaturedSub}>Compared to last mouth</span>
    </div>

  </div>
);

export default FeaturedInfo;
