import React, { FC } from 'react';
import styles from './FeedBacksList.module.css';

interface FeedBacksListProps {}

const FeedBacksList: FC<FeedBacksListProps> = () => (
  <div className={styles.FeedBacksList}>
    FeedBacksList Component
  </div>
);

export default FeedBacksList;
