import { useAuth0 } from "@auth0/auth0-react";

const LoginButton = () => {
  const { loginWithRedirect, isAuthenticated } = useAuth0();
  if (!isAuthenticated) {
    return <button type="button" className="application-login" onClick={() => loginWithRedirect()}>Log In</button>
  }
  else {
    return <div></div>
  }
};

export default LoginButton;