import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";

export interface UserState {
  isAuthenticated: boolean;
  accessToken: string;
  refreshToken: string;
  apiAccessToken:string;
}

const initialState: UserState = {
  isAuthenticated: false,
  accessToken: "",
  refreshToken: "",
  apiAccessToken: "",
};

export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    setAuthenticateState(state, action: PayloadAction<boolean>) {
      state.isAuthenticated = action.payload;
    },
    setAccessToken(state, action: PayloadAction<string>) {
      state.accessToken = action.payload;
    },
    setRefreshToken(state, action: PayloadAction<string>) {
      state.refreshToken = action.payload;
    },
    setApiAccessToken(state, action: PayloadAction<string>) {
      state.apiAccessToken = action.payload;
    },
    logoutUser: (state) => {
      localStorage.removeItem('token');
      state.isAuthenticated = false;
      state.apiAccessToken = "";
      state.accessToken = "";
      state.refreshToken="";
    },
  },
});

export const {
  setAuthenticateState,
  setAccessToken,
  setRefreshToken,
  setApiAccessToken,
  logoutUser,
} = authSlice.actions;
export default authSlice.reducer;
