import React, { FC } from 'react';
import LoginButton from '../../components/LoginButton/LoginButton';
import styles from './HomePublic.module.css';

interface HomePublicProps { }

const HomePublic: FC<HomePublicProps> = () => (
  <div className={styles.HomePublic}>
    
    <div className={styles.HomePublicTitleContainer}>
      <span className={styles.HomePublicTitle}>.: Tralo :.</span>
      <span className={styles.HomePublicSubtitle}>Track Every Things</span>
    </div>
    
    <div className={styles.HomePublicContainerLogin}>
      <LoginButton />
    </div>
    
    <div className={styles.HomePublicContainer}>
      
      <div className={styles.HomePublicItem}>
        <div className={styles.HomePublicItemTitle}>Title1</div>
        <div className={styles.HomePublicItemDescr}>
        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed finibus diam eget enim aliquet, a dictum turpis faucibus. Ut ultricies, justo quis volutpat volutpat, nulla augue imperdiet neque, eu lobortis lacus eros et nibh. Maecenas scelerisque ligula a elit iaculis dignissim. Phasellus non sodales tortor. 
        </div>
      </div>
      
      <div className={styles.HomePublicItem}>
        <div className={styles.HomePublicItemTitle}>Title2</div>
        <div className={styles.HomePublicItemDescr}>
        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed finibus diam eget enim aliquet, a dictum turpis faucibus. Ut ultricies, justo quis volutpat volutpat, nulla augue imperdiet neque, eu lobortis lacus eros et nibh. Maecenas scelerisque ligula a elit iaculis dignissim. Phasellus non sodales tortor. 
        </div>
      </div>
      
      <div className={styles.HomePublicItem}>
        <div className={styles.HomePublicItemTitle}>Title3</div>
        <div className={styles.HomePublicItemDescr}>
        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed finibus diam eget enim aliquet, a dictum turpis faucibus. Ut ultricies, justo quis volutpat volutpat, nulla augue imperdiet neque, eu lobortis lacus eros et nibh. Maecenas scelerisque ligula a elit iaculis dignissim. Phasellus non sodales tortor. 
        </div>
      </div>

    </div>

    <div className={styles.HomePublicFooter}>Tralo - @Copyright Keantex 2023 -</div>

  </div>
);

export default HomePublic;
