export const userRows = [
    { id: 1,transaction:"$120.00", avatar:"https://amerlin.keantex.com/wp-content/uploads/2019/01/profilo.jpg?57d607&57d607", status:"active", username: 'Snow', email: 'Jon@keantex.com', age: 35 },
    { id: 2,transaction:"$120.00", avatar:"https://amerlin.keantex.com/wp-content/uploads/2019/01/profilo.jpg?57d607&57d607", status:"active", username: 'Lannister', email: 'Cersei@keantex.com', age: 42 },
    { id: 3,transaction:"$120.00", avatar:"https://amerlin.keantex.com/wp-content/uploads/2019/01/profilo.jpg?57d607&57d607", status:"active", username: 'Lannister', email: 'Jaime@keantex.com', age: 45 },
    { id: 4,transaction:"$120.00", avatar:"https://amerlin.keantex.com/wp-content/uploads/2019/01/profilo.jpg?57d607&57d607", status:"active", username: 'Stark', email: 'Arya@keantex.com', age: 16 },
    { id: 5,transaction:"$120.00", avatar:"https://amerlin.keantex.com/wp-content/uploads/2019/01/profilo.jpg?57d607&57d607", status:"active", username: 'Targaryen', email: 'Daenerys@keantex.com', age: null },
    { id: 6,transaction:"$120.00", avatar:"https://amerlin.keantex.com/wp-content/uploads/2019/01/profilo.jpg?57d607&57d607", status:"active", username: 'Melisandre', email: null, age: 150 },
    { id: 7,transaction:"$120.00", avatar:"https://amerlin.keantex.com/wp-content/uploads/2019/01/profilo.jpg?57d607&57d607", status:"active", username: 'Clifford', email: 'Ferrara@keantex.com', age: 44 },
    { id: 8,transaction:"$120.00", avatar:"https://amerlin.keantex.com/wp-content/uploads/2019/01/profilo.jpg?57d607&57d607", status:"active", username: 'Frances', email: 'Rossini@keantex.com', age: 36 },
    { id: 9,transaction:"$120.00", avatar:"https://amerlin.keantex.com/wp-content/uploads/2019/01/profilo.jpg?57d607&57d607", status:"active", username: 'Roxie', email: 'Harvey@keantex.com', age: 65 },
    { id: 10,transaction:"$120.00", avatar:"https://amerlin.keantex.com/wp-content/uploads/2019/01/profilo.jpg?57d607&57d607", status:"active", username: 'Roxie', email: 'Harvey@keantex.com', age: 65 },
    { id: 11,transaction:"$120.00", avatar:"https://amerlin.keantex.com/wp-content/uploads/2019/01/profilo.jpg?57d607&57d607", status:"active", username: 'Roxie', email: 'Harvey@keantex.com', age: 65 },
    { id: 12,transaction:"$120.00", avatar:"https://amerlin.keantex.com/wp-content/uploads/2019/01/profilo.jpg?57d607&57d607", status:"active", username: 'Roxie', email: 'Harvey@keantex.com', age: 65 },
    { id: 13,transaction:"$120.00", avatar:"https://amerlin.keantex.com/wp-content/uploads/2019/01/profilo.jpg?57d607&57d607", status:"active", username: 'Roxie', email: 'Harvey@keantex.com', age: 65 },
    { id: 14,transaction:"$120.00", avatar:"https://amerlin.keantex.com/wp-content/uploads/2019/01/profilo.jpg?57d607&57d607", status:"active", username: 'Roxie', email: 'Harvey@keantex.com', age: 65 },
  ];