import React, { FC } from 'react';
import styles from './ProductList.module.css';

interface ProductListProps {}

const ProductList: FC<ProductListProps> = () => (
  <div className={styles.ProductList}>
     <h1>Products List</h1>
  </div>
);

export default ProductList;
