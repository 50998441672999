import React, { FC } from 'react';
import styles from './AdminReports.module.css';

interface AdminReportsProps {}

const AdminReports: FC<AdminReportsProps> = () => (
  <div className={styles.AdminReports}>
    AdminReports Component
  </div>
);

export default AdminReports;
