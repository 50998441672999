import { FC, useEffect } from 'react';
import { useAuth0 as authService } from '@auth0/auth0-react';
import Chart from '../../components/Chart/Chart';
import FeaturedInfo from '../../components/FeaturedInfo/FeaturedInfo';
import styles from './Home.module.css';
import { data } from '../../dummyData/chartData';
import WidgetLg from '../../components/WidgetLg/WidgetLg';
import WidgetSm from '../../components/WidgetSm/WidgetSm';
import { useDispatch, useSelector } from 'react-redux';
import { setAccessToken, setApiAccessToken, setAuthenticateState } from '../../features/auth/authSlice';
import { ApplicationUser } from '../../Models/ApplicationUser';
import { setUserData } from '../../features/user/userSlice';
import { RootState } from '../../app/store';


interface HomeProps { }

const Home: FC<HomeProps> = () => {
  const { user, getAccessTokenSilently } = authService();
  const dispatch = useDispatch();
  let accessToken = useSelector((state: RootState) => state.auth.accessToken);
  let apiAccessToken = useSelector((state: RootState) => state.auth.accessToken);

  useEffect(() => {

    const getUserProfile = async () => {
      const domain = process.env.REACT_APP_DOMAIN;
      const audienceURL = process.env.REACT_APP_AUDIENCE_URL;
      if (!accessToken && !apiAccessToken) {
        try {
          const access = await getAccessTokenSilently({
            audience: `https://${domain}/api/v2/`,
            scope: "read:current_user",
            ignoreCache: true
          });
          const apiAccess = await getAccessTokenSilently({
            audience: audienceURL,
            scope: "todo:read-write",
            ignoreCache: true
          });

          //TODO: verificare questa richiesta
          if (user != null) {
            const userDetailsByIdUrl = `https://${domain}/api/v2/users/${user.sub}`;
            const metadataResponse = await fetch(userDetailsByIdUrl, {
              headers: {
                Authorization: `Bearer ${access}`,
              },
            });
            const user_metadata = await metadataResponse.json();
            console.log(user_metadata);
            console.log(metadataResponse);

            let roles: string[] = [];
            let userRoles = user["http://schemas.microsoft.com/ws/2008/06/identity/claims/role"];
            userRoles.forEach((element: string) => {
              roles.push(element);
            });
            let userData: ApplicationUser = { email: user.email!, firstName: "", lastName: "", roles: roles };

            dispatch(setAuthenticateState(true));
            dispatch(setAccessToken(access));
            dispatch(setApiAccessToken(apiAccess));
            dispatch(setUserData(userData));

          }
        } catch (e) {
          console.log(e.message);
        }
      }
    };

    getUserProfile();
  }, [getAccessTokenSilently, user?.sub, user, dispatch, accessToken, apiAccessToken]);

  return (
    <div className={styles.Home}>
      <FeaturedInfo />
      <Chart title="User Analytics" dataGrid={true} dataKey="Active User" data={data} />
      <div className={styles.HomeWidgets}>
        <WidgetSm />
        <WidgetLg />
      </div>
    </div>);

};

export default Home;
