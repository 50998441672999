import React, { FC } from 'react';
import styles from './SalesList.module.css';

interface SalesListProps {}

const SalesList: FC<SalesListProps> = () => (
  <div className={styles.SalesList}>
    SalesList Component
  </div>
);

export default SalesList;
