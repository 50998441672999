import React, { FC } from 'react';
import styles from './AdminAnalytics.module.css';

interface AdminAnalyticsProps {}

const AdminAnalytics: FC<AdminAnalyticsProps> = () => (
  <div className={styles.AdminAnalytics}>
    AdminAnalytics Component
  </div>
);

export default AdminAnalytics;
