import React, { FC, useState } from 'react';
import styles from './UserList.module.css';
import { userRows } from '../../dummyData/userRows';
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import { Link } from 'react-router-dom';

interface UserListProps {}

const UserList: FC<UserListProps> = () => {
  const [data, setData] = useState(userRows);

  const handleDelete = (id: number) => {
    setData(data.filter(item => item.id !== id));
  }

  const columns: GridColDef[] = [
    { field: 'id', headerName: 'ID', width: 90 },
    { field: 'user', headerName: 'User', width: 200, renderCell: (params)=>{
      return (
        <div className={styles.UserListName}>
          <img src={params.row.avatar} alt="avatar" className={styles.UserListImg}/>
          {params.row.username}
        </div>
      )
    }},
    { field: 'email', headerName: 'Email', width: 200 },
    { field: 'status', headerName: 'Status', width: 120},
    { field: 'transaction', headerName: 'Transaction', width: 160},
    {
      field:"action", headerName:"Action", width: 150, renderCell: (params)=>{
        return (
          <>
            <Link to={"/user/" + params.row.id}>
              <button className={styles.UserListEdit}>Edit</button>
            </Link>
            <DeleteOutlineIcon className={styles.UserListDelete} onClick={()=>handleDelete(params.row.id)}/>
          </>
        )
      }
    }
  ];
  
  return (
    <div className={styles.UserList}>
       <DataGrid
        rows={data}
        disableSelectionOnClick
        columns={columns}
        pageSize={8}
        checkboxSelection
      />
    </div>);
};

export default UserList;
