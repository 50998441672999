import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import { Source } from "../../Models/Source";

export interface SourceState {
  Sources: Source[];
}

const initialState: SourceState = {
  Sources: [],
};

export const counterSlice = createSlice({
  name: "sources",
  initialState,
  reducers: {
    setPlacesData: (state, action: PayloadAction<Source[]>) => {
        action.payload.forEach(element => {
            let newElement: Source = {
              Id : element.Id,
              Description:  element.Description,
              Address: element.Address,
              City: element.City,
            };
            state.Sources.push(newElement);
        });
    },
  },
});

// Action creators are generated for each case reducer function
export const { setPlacesData } = counterSlice.actions;
export default counterSlice.reducer;
