import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import { Track } from "../../Models/Track";

export interface TrackState {
    Tracks: Track[];
}

const initialState: TrackState = {
  Tracks: [],
};

export const counterSlice = createSlice({
  name: "tracks",
  initialState,
  reducers: {
    setTracksData: (state, action: PayloadAction<Track[]>) => {
        action.payload.forEach(element => {
            let newElement: Track = {
              Id : element.Id,
              Description:  element.Description,
            };
            state.Tracks.push(newElement);
        });
    },
  },
});

// Action creators are generated for each case reducer function
export const { setTracksData } = counterSlice.actions;
export default counterSlice.reducer;
