import React, { FC } from 'react';
import styles from './NewUser.module.css';

interface NewUserProps {}

const NewUser: FC<NewUserProps> = () => (
  <div className={styles.NewUser}>
    <h1 className="NewUserTile">New User</h1>
    <form className={styles.NewUserForm}>
      <div className={styles.NewUserItem}>
        <label>Username</label>
        <input type="text" placeholder="john"></input>
      </div>
      <div className={styles.NewUserItem}>
        <label>FullName</label>
        <input type="text" placeholder="John Smith"></input>
      </div>
      <div className={styles.NewUserItem}>
        <label>Email</label>
        <input type="email" placeholder="john@gmail.com"></input>
      </div>
      <div className={styles.NewUserItem}>
        <label>Password</label>
        <input type="password" placeholder="password"></input>
      </div>
      <div className={styles.NewUserItem}>
        <label>Phone</label>
        <input type="password" placeholder="+1 123 456 67"></input>
      </div>
      <div className={styles.NewUserItem}>
        <label>Address</label>
        <input type="text" placeholder="New York | USA"></input>
      </div>
      <div className={styles.NewUserItem}>
        <label>Gender</label>
          <div className={styles.NewUserGender}>
          <input type="radio" name="gender" id="male" value="male"/>
          <label htmlFor="male">Male</label>
          <input type="radio" name="gender" id="female" value="female"/>
          <label htmlFor="female">Female</label>
          <input type="radio" name="gender" id="other" value="other"/>
          <label htmlFor="other">Other</label>
        </div>
      </div>
      <div className={styles.NewUserItem}>
        <label>Active</label>
        <select className={styles.NewUserSelect} name="active" id="active">
          <option value="yes">Yes</option>
          <option value="no">No</option>
        </select>
      </div>
      <button className={styles.NewUserButton}>Create</button>
    </form>
  </div>
);

export default NewUser;
