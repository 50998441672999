import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import { ApplicationUser } from "../../Models/ApplicationUser";

export interface UserState {
  firstName: string;
  lastName: string;
  email: string;
  roles: string[];
}

const initialState: UserState = {
  firstName: "",
  lastName: "",
  email: "",
  roles: [],
};

export const counterSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    setUserData: (state, action: PayloadAction<ApplicationUser>) => {
      state.firstName = action.payload.firstName;
      state.lastName = action.payload.lastName;
      state.email = action.payload.email;
      state.roles = action.payload.roles;
    },
  },
});

// Action creators are generated for each case reducer function
export const { setUserData } = counterSlice.actions;
export default counterSlice.reducer;
